import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import Testimonials from "../components/Repeating/Testimonials";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithIcon from "../components/Button/ButtonWithIcon";
import { mapEdgesToNodes } from "../lib/helpers";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query TeamMemberTemplateQuery($id: String!) {
    page: sanityTeamMember(id: { eq: $id }) {
      id
      slug {
        current
      }
      seoTitle
      metaDescription
      name
      headshot {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 330)
        }
      }
      directPhoneNumber
      emailAddress
      linkedinProfile
      hubspotID
      title
      hometown
      yearsExperience
      education {
        asset {
          gatsbyImageData
        }
      }
      quote
      quoteAuthor
      _rawBiography
      certifications {
        listItem
      }
      specialties {
        listItem
      }
      industries {
        listItem
      }
    }
    testimonials: allSanityTestimonials {
      edges {
        node {
          categories {
            title
          }
        }
      }
    }
    grayCrosses: file(relativePath: { eq: "locations/gray-crosses.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const TeamMemberTemplate = (props) => {
  const { data, errors } = props;
  const page = data && data.page;

  const truncate = (str) => {
    return str.length > 300 ? str.substring(0, 300) : str;
  };

  const testimonialsNodes = (data || {}).testimonials
    ? mapEdgesToNodes(data.testimonials).filter((items) =>
        items.categories.find((item) => item.title === page.name)
      )
    : [];

  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {page && (
        <SearchEngineOptimization
          title={page.seoTitle}
          description={page.metaDescription}
          openGraphImage={page.headshot && page.headshot.asset.url}
          twitterOpenGraphImage={page.headshot && page.headshot.asset.url}
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {page && (
        <>
          <section className="relative mb-64 pt-8 md:mb-72 md:pt-12">
            <div className="absolute top-0 left-0 h-full w-full bg-primary-900"></div>
            <div className="absolute top-0 left-0 h-full w-full ">
              <GatsbyImage
                image={data.grayCrosses.childImageSharp.gatsbyImageData}
                className="h-full"
              />
            </div>
            <div className="container relative">
              <div className="relative -bottom-44 -mt-40 rounded-xl bg-secondary-100 px-4 py-6 md:px-16 md:py-16">
                <div className="grid grid-cols-1 md:grid-cols-12 md:gap-x-10">
                  <div className="flex flex-col justify-between md:col-span-4 md:col-start-1">
                    <div>
                      <div className="relative mx-auto mb-6 h-22 w-22 overflow-hidden rounded-full border-2 border-primary-500 md:mx-0 md:mb-8 md:h-auto md:w-auto md:overflow-visible md:rounded-none md:border-none md:pl-1.5 md:pt-1.5">
                        <div className="absolute left-0 top-0 hidden h-full w-full rounded-4xl bg-primary-500 md:block"></div>
                        <GatsbyImage
                          image={page.headshot.asset.gatsbyImageData}
                          className="z-0 rounded-full md:rounded-4xl"
                        />
                      </div>

                      {page.certifications.length > 0 && (
                        <div className="mb-8 hidden md:block">
                          <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                            Certifications
                          </div>
                          <ul className="styled-list-star">
                            {page.certifications.map((certification, i) => {
                              return <li key={i}>{certification.listItem}</li>;
                            })}
                          </ul>
                        </div>
                      )}
                    </div>

                    {page.hubspotID && (
                      <div className="hidden md:block">
                        <ButtonSolid
                          href={`/book-a-meeting/?hubspotID=${page.hubspotID}`}
                          text="Book a Meeting"
                          className="w-full"
                        />
                      </div>
                    )}
                  </div>
                  <div className="md:col-span-8 md:col-end-13">
                    <h1 className="heading-two text-center md:mb-8 md:text-left">
                      {page.name}
                    </h1>

                    <div className="mb-5 flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-6">
                      {page.directPhoneNumber && (
                        <a
                          href={`tel:${page.directPhoneNumber
                            .replace(/[()]/g, "")
                            .replace(/ /g, "-")}`}
                          target="_blank"
                          rel="noreferrer"
                          className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                        >
                          <i className="fa-solid fa-phone mr-1.5 text-xl text-secondary-500"></i>
                          <span className="font-semibold">
                            {page.directPhoneNumber}
                          </span>
                        </a>
                      )}

                      {page.emailAddress && (
                        <a
                          href={`mailto:${page.emailAddress}`}
                          target="_blank"
                          rel="noreferrer"
                          className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                        >
                          <i className="fa-solid fa-envelope mr-1.5 text-xl text-secondary-500"></i>
                          <span className="font-semibold">
                            {page.emailAddress}
                          </span>
                        </a>
                      )}

                      {page.linkedinProfile && (
                        <a
                          href={page.linkedinProfile}
                          target="_blank"
                          rel="noreferrer"
                          className="flex w-full items-center justify-center text-primary-900 no-underline hover:text-secondary-500 md:justify-start lg:w-auto"
                        >
                          <i className="fab fa-linkedin mr-1.5 text-2xl text-secondary-500"></i>
                          <span className="font-semibold">LinkedIn</span>
                        </a>
                      )}
                    </div>

                    <div className="mb-6 grid grid-cols-2 rounded-3xl border border-gray-900/40 bg-white text-center md:mb-10 md:flex md:rounded-lg md:text-left">
                      {page.title && (
                        <div
                          className={`border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                        >
                          <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                            Title
                          </div>
                          <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                            {page.title}
                          </div>
                        </div>
                      )}

                      {page.hometown && (
                        <div
                          className={`border-l border-b border-primary-900/40 py-5 px-4 md:flex-1 md:border-b-0 md:py-4`}
                        >
                          <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                            Hometown
                          </div>
                          <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                            {page.hometown}
                          </div>
                        </div>
                      )}

                      {page.yearsExperience && (
                        <div
                          className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${
                            page.hometown === null
                              ? "border-l border-b md:border-b-0"
                              : "md:border-l"
                          }`}
                        >
                          <div className="mb-0.5 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                            Years of Experience
                          </div>
                          <div className="text-sm font-semibold text-primary-900 md:text-lg md:font-bold">
                            {page.yearsExperience}
                          </div>
                        </div>
                      )}

                      {page.education.length > 0 && (
                        <div
                          className={`border-primary-900/40 py-5 px-4 md:flex-1 md:py-4 ${
                            page.hometown === null &&
                            page.yearsExperience === null
                              ? "border-l"
                              : page.hometown === null ||
                                page.yearsExperience === null
                              ? "col-span-2 md:border-l"
                              : "border-l"
                          }`}
                        >
                          <div className="mb-1 text-xs font-bold uppercase tracking-wide text-primary-900/40 md:mb-2.5">
                            Education
                          </div>
                          <div
                            className={`grid items-center justify-center gap-x-3 md:justify-start md:gap-x-4 ${
                              page.education.length > 1 && "grid-cols-2"
                            }`}
                          >
                            {page.education.map((education, i) => {
                              return (
                                <GatsbyImage
                                  image={education.asset.gatsbyImageData}
                                  className={`${
                                    page.education.length === 1 &&
                                    "max-w-[90px]"
                                  }`}
                                />
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>

                    {page.hubspotID && (
                      <div className="mb-6 md:hidden">
                        <ButtonSolid
                          href={`/book-a-meeting/?hubspotID=${page.hubspotID}`}
                          text="Book a Meeting"
                          className="w-full"
                        />
                      </div>
                    )}

                    {page.quote && (
                      <figure>
                        <blockquote>
                          <q className="mb-4 block font-bold italic">
                            {page.quote}
                          </q>
                        </blockquote>
                        {page.quoteAuthor && (
                          <figcaption className="mb-4 -mt-2 block text-sm font-bold">
                            &mdash; {page.quoteAuthor}
                          </figcaption>
                        )}
                      </figure>
                    )}

                    {page._rawBiography && (
                      <>
                        <div className="relative mb-1 h-20 overflow-hidden">
                          <p className="mb-0">
                            {truncate(toPlainText(page._rawBiography))}
                          </p>
                          <div className="absolute left-0 bottom-0 h-full w-full bg-gradient-to-t from-secondary-100 to-white/10"></div>
                        </div>
                        <ButtonWithIcon
                          icon="fa-solid fa-arrow-down"
                          href={`/${page.slug.current}/#bio`}
                          text="Read Full Bio"
                        />
                      </>
                    )}

                    {(page.specialties.length > 0 ||
                      page.industries.length > 0) && (
                      <div className="mt-10 hidden gap-x-4 gap-y-8 md:grid md:grid-cols-2">
                        {page.specialties.length > 0 && (
                          <div>
                            <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                              Specialties
                            </div>
                            <ul className="styled-list-plus">
                              {page.specialties.map((specialty, i) => {
                                return <li key={i}>{specialty.listItem}</li>;
                              })}
                            </ul>
                          </div>
                        )}

                        {page.industries.length > 0 && (
                          <div>
                            <div className="mb-3.5 text-xs font-bold uppercase tracking-wide text-primary-900/40">
                              Industries
                            </div>
                            <ul className="styled-list-plus">
                              {page.industries.map((industry, i) => {
                                return <li key={i}>{industry.listItem}</li>;
                              })}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="mb-20 md:mb-32" id="bio">
            <div className="container">
              <div className="mx-auto max-w-[720px]">
                <header className="mb-8 md:mb-10">
                  <h2>About</h2>
                </header>

                <div className="mb-12 md:text-xl">
                  <PortableText blocks={page._rawBiography} />
                </div>

                {page.hubspotID && (
                  <div className="text-center">
                    <ButtonSolid
                      href={`/book-a-meeting/?hubspotID=${page.hubspotID}`}
                      text="Book a Meeting"
                      className="w-full md:w-auto"
                    />
                  </div>
                )}
              </div>
            </div>
          </section>

          {testimonialsNodes.length > 0 ? (
            <Testimonials category={page.name} />
          ) : (
            <Testimonials />
          )}

          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default TeamMemberTemplate;
